<template>
    <section class="cotizaciones-realizadas container-fluid">
        <titulo-divisor titulo="Datos básicos" />
        <div class="row mx-0 my-3">
            <card-dato-adicional name="Nombre del proveedor" :descripcion="`${informationProvider.nombre}`" />
            <card-dato-adicional name="Teléfono" :descripcion="`${informationProvider.telefono}`" />
            <card-dato-adicional name="Ciudad principal" :descripcion="`${informationProvider.ciudad}`" />
            <card-dato-adicional name="Dirección" :descripcion="`${informationProvider.direccion}`" />
            <card-dato-adicional name="Correo" :descripcion="`${informationProvider.correo}`" />
            <card-dato-adicional name="Descripción" :descripcion="`${informationProvider.descripcion}`" />
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            informationProvider: 'users_providers/usersProviders/informationProvider',
        }),
    },
    async mounted(){
        await this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['proveedores.usuarios']);
        await this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs',[
            {
                position: 2,
                breadcumb: {
                    name: this.informationProvider.nombre,
                    route: 'proveedores.usuarios.informacion',
                }
            }
        ])
            
    }
}
</script>

<style lang="scss" scoped>
.cotizaciones-realizadas{
    background: white;
}
</style>